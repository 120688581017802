import { ExternalLink } from 'react-external-link';
import './Header.css';

// import AvdCLogo from  '../../imgs/assets/logo.svg';
import { MdAudiotrack, MdOutlineAudiotrack } from 'react-icons/md';

const Header = ( { dados, mutedToggleHandler, muted }) => {

    console.log("dados.header_textarea ::", dados.header_textarea);
    console.log("dados.header_textarea_url ::", dados.header_textarea_url);

    return (
        <>
            <div className='linksHeader filter-green'>
                <img src={dados.header_logo} alt="" className='logo' />
                <div>
                    <ExternalLink href={dados.header_link_url}>
                        {dados.header_link_text}
                    </ExternalLink>
                </div>
                
                <div className='textarea'>
                    {
                        dados.header_textarea_url 
                        ?
                            <ExternalLink href={dados.header_textarea_url}>
                                {dados.header_textarea}
                            </ExternalLink>
                        :
                        dados.header_textarea
                    }
                </div>
                <div 
                    style   = { { cursor: "pointer"} } 
                    onClick={() => { mutedToggleHandler(); }}
                >
                    { muted ? <MdOutlineAudiotrack /> : <MdAudiotrack /> }
                </div>
            </div>
            <div className="mobileHeader" >
                <img src={dados.header_logo} alt="" className="logoMobile" />
                <div className='linksHeaderMobile'>
                    <div style = { { cursor: "pointer"} } onClick={() => { mutedToggleHandler(); }} >
                        { muted ? <MdOutlineAudiotrack /> : <MdAudiotrack /> }
                    </div>
                    <div>
                        <ExternalLink href={dados.header_link_url}>{dados.header_link_text}</ExternalLink>
                    </div>
                    <div>{dados.header_textarea}</div>
                </div>
            </div>
        </>
    );
}

export default Header