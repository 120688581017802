import "./App.css";
import Header from "./Components/UI/Header";
import Player from "./Components/Player";
// import PlayerTESTE from "./Components/PlayerTESTE";
import RasgaoBottom from "./Components/UI/rasgaoBottom";
import RasgaoTopTop from "./Components/UI/rasgaoTopTop";
import RasgaoTop from "./Components/UI/rasgaoTop";
import ListenTo from "./Components/ListenTo";
import Slider from "./Components/Slider";
import FundoBarra from "./Components/UI/FundoBarra";

import {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "./firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";

gsap.registerPlugin(ScrollTrigger);

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
logEvent(firebaseAnalytics, 'Analytics activado!!');

function App() {

    const [isListenToVisible, setIsListenToVisible] = useState(true);
    const [mutedToggle, setMutedToggle] = useState(true);
    const [dados, setDados] = useState({
        slide5_filename : "",
        slide6_filename : "",
        slide7_filename : "",
        slide5_url : "",
        slide6_url : "",
        slide7_url : "",
    });

    const mutedToggleHandler = () => { setMutedToggle(!mutedToggle); }

    const scrollToTop = () => { window.scrollTo({ top: 0,  behavior: 'smooth' }); }; 

    const playerRef = useRef(null);
    
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => { window.removeEventListener("scroll", listenToScroll); };
    },);


    useEffect( () => {
        async function getData () {
            const response = await fetch('https://api.storyblok.com/v2/cdn/stories/landing-page?version=draft&token=UoPBKgC8HheIpx9L9vyFgAtt');
            const data = await response.json(); 

            setDados(
            {
                header_link_text : data.story.content.header_link_text,
                header_link_url  : data.story.content.header_link_url,
                header_logo      : data.story.content.header_logo.filename,
                header_textarea  : data.story.content.header_textarea,
                header_textarea_url : data.story.content.header_textarea_url,
                header_background_video : data.story.content.header_background_video,
                header_background_video_length : data.story.content.header_background_video_length,

                slide1_filename  : data.story.content.slide1.filename,
                slide2_filename  : data.story.content.slide2.filename,
                slide3_filename  : data.story.content.slide3.filename,
                slide4_filename  : data.story.content.slide4.filename,
                slide5_filename  : data.story.content.slide5.filename,
                slide6_filename  : data.story.content.slide6.filename,
                slide7_filename  : data.story.content.slide7.filename,
                slide8_filename  : data.story.content.slide8.filename,
                slide9_filename  : data.story.content.slide9.filename,
                slide10_filename : data.story.content.slide10.filename,
                slide11_filename : data.story.content.slide11.filename,
                slide5_url : data.story.content.slide5_url,
                slide6_url : data.story.content.slide6_url,
                slide7_url : data.story.content.slide7_url,
                slide8_url : data.story.content.slide8_url,
                slide9_url : data.story.content.slide9_url,
                slide10_url : data.story.content.slide10_url,

                rasgao_textarea : data.story.content.rasgao_textarea,
                rasgao_link1_url : data.story.content.rasgao_link1_url,
                rasgao_link2_url : data.story.content.rasgao_link2_url,
                rasgao_link3_url : data.story.content.rasgao_link3_url,
                rasgao_link1_texto : data.story.content.rasgao_link1_texto,
                rasgao_link2_texto : data.story.content.rasgao_link2_texto,
                rasgao_link3_texto : data.story.content.rasgao_link3_texto,
                rasgao_minilink_url : data.story.content.rasgao_minilink_url,
                rasgao_minilink_texto : data.story.content.rasgao_minilink_texto,
                rasgao_legenda1 : data.story.content.rasgao_legenda1,
                rasgao_legenda1_url : data.story.content.rasgao_legenda1_url,
                rasgao_legenda1_texto : data.story.content.rasgao_legenda1_texto,
                rasgao_legenda2 : data.story.content.rasgao_legenda2,
                rasgao_legenda2_url : data.story.content.rasgao_legenda2_url,
                rasgao_legenda2_texto : data.story.content.rasgao_legenda2_texto,
                
                footer_logo : data.story.content.footer_logo.filename,
                footer_texto : data.story.content.footer_texto,

            });

        }
    
        getData();

    }, []);

    const listenToScroll = () => {
        let heightToHideFrom = 1500;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) isListenToVisible && setIsListenToVisible(false);
        else setIsListenToVisible(true);
    };

    return (
        <div className="App">
            <div className="moving_div">
                <RasgaoTopTop/>
                <div className="tecido">
                    <Slider 
                        dados={dados}
                    />
                </div>
                <div className="listenTo">
                    <ListenTo dados={dados} />
                </div>
                <div id="resto">
                    <div className="blur">
                        <RasgaoBottom/>
                        <RasgaoTop/>
                    </div>
                    <FundoBarra/>
                    <div className="secret">
                        <img src={dados.footer_logo} alt="" className="footerLogo" />
                        <div className="legenda">
                            {dados.footer_texto}<br/>
                            <span style={{cursor:"pointer"}} onClick={scrollToTop}> BACK TO TOP!</span>
                        </div>
                    </div>
                    <div className="secretMobile">
                        <img src={dados.footer_logo} alt="" className="footerLogo" />
                        <div className="legenda">
                            {dados.footer_texto}<br/>
                            <span style={{cursor:"pointer"}} onClick={scrollToTop}> BACK TO TOP!</span>
                        </div>
                    </div>
                </div>
                <div className="fixed_div">
                    <Player
                        forwardPlayerRef={playerRef}
                        mutedToggle={mutedToggle} 
                        video_vimeo_url={dados.header_background_video}
                        video_vimeo_length={dados.header_background_video_length}
                    />
                    {/* <PlayerTESTE /> */}
                    { 
                        isListenToVisible && 
                        <Header 
                            dados={dados}
                            mutedToggleHandler = { mutedToggleHandler }
                            muted = { mutedToggle } 
                            forwardPlayerRef={playerRef} 
                        /> 
                    }
                </div>
            </div>
        </div>
    );
}

export default App;