import ripped_top from "../../imgs/assets/ripped_top_light@2x.png";
import "./rasgaoTop.css";

const RasgaoTop = () => {
    return (
        <img
            className="imgRippedTop"
            src={ripped_top}
            alt=""
            style={{width: "100%", zIndex:"2", transform: "translateY(6px)" }} 
        />);
};

export default RasgaoTop;
