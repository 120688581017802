import "./ListenTo.css";

const ListenTo = ({dados}) => {
    return (
        <>
            <div className="grupo">
                <div className="left-side">
                    <div className="title">
                        {dados.rasgao_textarea}
                    </div>
                    <div className="link linkanim">
                        <a href={dados.rasgao_link1_url}target="_blank" rel="noopener noreferrer">{dados.rasgao_link1_texto}</a>
                    </div>
                    <div className="link linkanim">
                        <a href={dados.rasgao_link2_url} target="_blank" rel="noopener noreferrer">{dados.rasgao_link2_texto}</a>
                    </div>
                    <div className="link linkanim">
                        <a href={dados.rasgao_link3_url} target="_blank" rel="noopener noreferrer">{dados.rasgao_link3_texto}</a>
                    </div>
                    <br />
                    <div className="subtitle linkanim">
                        <a href={dados.rasgao_minilink_url}>{dados.rasgao_minilink_texto}</a>
                    </div>
                </div>
                <div className="right-side">
                    <div className="subtitle">{dados.rasgao_legenda1}</div>
                    <div className="email linkanim">
                        <a href={dados.rasgao_legenda1_url}>{dados.rasgao_legenda1_texto}</a>
                    </div>
                    <div className="subtitle">{dados.rasgao_legenda2}</div>
                    <div className="email linkanim">
                        <a href={dados.rasgao_legenda2_url}>{dados.rasgao_legenda2_texto}</a>
                    </div>
                </div>
            </div>
            <div className="grupoMobile">
                <div className="title">{dados.rasgao_textarea}</div>
                <div className="grupoLinks">
                    <div className="link linkanim"><a href={dados.rasgao_link1_url} target="_blank" rel="noopener noreferrer">{dados.rasgao_link1_texto}</a></div>
                    <div className="link linkanim"><a href={dados.rasgao_link2_url} target="_blank" rel="noopener noreferrer">{dados.rasgao_link2_texto}</a></div>
                    <div className="link linkanim"><a href={dados.rasgao_link3_url} target="_blank" rel="noopener noreferrer">{dados.rasgao_link3_texto}</a></div>
                </div>
                <div className="grupoDownload">
                    <div className="subtitle linkanim"><a href={dados.rasgao_minilink_url}>{dados.rasgao_minilink_texto}</a></div>
                </div>
                <div className="grupoEmails">
                    <div className="subtitle">{dados.rasgao_legenda1}</div>
                    <div className="email linkanim">
                        <a href={dados.rasgao_legenda1_url}>{dados.rasgao_legenda1_texto}</a>
                    </div>
                    <div className="subtitle">{dados.rasgao_legenda2}</div>
                    <div className="email linkanim">
                        <a href={dados.rasgao_legenda2_url}>{dados.rasgao_legenda2_texto}</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListenTo;
