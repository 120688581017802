import "./FundoBarra.css";
import risco from "../../imgs/assets/drop@2x.png";

const FundoBarra = () => {
  return (
      <div className="fundo">
        <img src={risco} alt="" className="risco" />
      </div>
  );
}

export default FundoBarra;