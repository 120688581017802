// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBOauBqQXAqHr5HBE5SOwZd9sSNxFO53hk",
  authDomain: "antoniodaconceicaomusic.firebaseapp.com",
  projectId: "antoniodaconceicaomusic",
  storageBucket: "antoniodaconceicaomusic.appspot.com",
  messagingSenderId: "619698793853",
  appId: "1:619698793853:web:12e30ffc1dc2e053bfb014",
  measurementId: "G-L1FN5DDMQ8"
};

