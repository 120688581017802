import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";
import "./Slider.css";
import { ExternalLink } from 'react-external-link';

const Slider = ( props ) => {

    const el = useRef();
    const tl = useRef();

    useEffect(() => {
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: "0% 0%",
                end: "180% 0%",
                pin: "#ImgWrapper",
                onLeave: ()=>{
                    console.log("leave")
                },
                scrub: 1,
            }
        });

        gsap.to('body', {
            opacity: 1, duration: 1.3,
        })

        tl.current
            .to(("#img01"), { z: 8600  }, 0)
            .to(("#img02"), { z: 6700  }, 0)
            .to(("#img03"), { z: 4200  }, 0)
            .to(("#img04"), { z: 3600  }, 0)
            .to(("#img05"), { z: 2400  }, 0)
            .to(("#img06"), { z: 2200  }, 0)
            .to(("#img07"), { z: 1600  }, 0)
            .to(("#img08"), { z: 1400  }, 0)
            .to(("#img09"), { z: 960   }, 0)
            .to(("#img10"), { z: 740   }, 0)
            .to(("#img11"), { z: 400   }, 0);

        return () => {
            tl.current.kill();
            tl.current.scrollTrigger.kill();
        };
    },[]);
    
    return (
        <>
            <div id="pinIt" ref={el} className="slider">
                <div id="ImgWrapper" >                    
                    <div id="img01"><img src={props.dados.slide1_filename} alt=""/></div>
                    <div id="img02"><img src={props.dados.slide2_filename} alt=""/></div>
                    <div id="img03"><img src={props.dados.slide3_filename} alt=""/></div>
                    <div id="img04"><img src={props.dados.slide4_filename} alt=""/></div> 
                    <div id="img05"> 
                        {/* SOUNDCLOUD */}
                        <ExternalLink href={props.dados.slide5_url} >
                            <img id="img05" src={props.dados.slide5_filename} alt=""/>
                        </ExternalLink>
                    </div>
                    <div id="img06">
                        {/* INSTAGRAM */}
                        <ExternalLink href={props.dados.slide6_url} >
                            <img id="img06" src={props.dados.slide6_filename} alt=""/> 
                        </ExternalLink>
                    </div>
                    <div id="img07">
                        {/* YOUTUBE */}
                        <ExternalLink href={props.dados.slide7_url} >
                            <img src={props.dados.slide7_filename} alt=""/>
                        </ExternalLink>
                    </div>
                    <div id="img08">
                        {/* SPOTIFY */}
                        <ExternalLink href={props.dados.slide8_url} >
                            <img src={props.dados.slide8_filename} alt=""/> 
                        </ExternalLink>
                    </div>
                    <div id="img09">
                        {/* PRESS */}
                        <ExternalLink href={props.dados.slide9_url} >
                            <img src={props.dados.slide9_filename} alt=""/> 
                        </ExternalLink>
                    </div>
                    <div id="img10">
                        {/* SHOP */}
                        <ExternalLink href={props.dados.slide10_url} >
                            <img src={props.dados.slide10_filename} alt=""/>  
                        </ExternalLink>
                    </div>
                    <div id="img11"><img src={props.dados.slide11_filename} alt=""/></div>
                </div>
            </div>
            <div className="sliderMobile">
                <img src={props.dados.slide1_filename} alt="" />
                <img src={props.dados.slide2_filename} alt="" />
                <img src={props.dados.slide3_filename} alt="" />
                <img src={props.dados.slide4_filename} alt="" />
                <a href={props.dados.slide5_url}><img src={props.dados.slide5_filename} alt=""/></a>
                <a href={props.dados.slide6_url}><img src={props.dados.slide6_filename} alt=""/></a>
                <a href={props.dados.slide7_url}><img src={props.dados.slide7_filename} alt=""/></a>
                <a href={props.dados.slide8_url}><img src={props.dados.slide8_filename} alt=""/></a>
                <a href={props.dados.slide9_url}><img src={props.dados.slide9_filename} alt=""/></a>
                <a href={props.dados.slide10_url}><img src={props.dados.slide10_filename} alt=""/></a>
                <img src={props.dados.slide11_filename} alt="" />
            </div>
        </>
        );
    };

export default Slider;
