import "./Player.css";
import ReactPlayer from 'react-player'
import { useEffect } from "react";

function geraTempoRandom(min, max) {
    const tempo = Math.floor(Math.random() * (max - min + 1)) + min;
    // const minutos = Math.floor(tempo / 60);
    // const segundos = tempo - minutos * 60;
    // const str = "#t=" + minutos + "m" + segundos + "s";

    return tempo;
}

const Player = ({forwardPlayerRef, mutedToggle}) => {
    let randomTime = geraTempoRandom(1, 1748);

    useEffect( () => {
        forwardPlayerRef.current.seekTo(randomTime);
    }, []); 

    return (
        <div className="vimeo-wrapper">
            <div className="vimeo-frame">
                <ReactPlayer 
                    className={"vimeo-frame"}
                    ref={forwardPlayerRef}
                    width='100%'
                    height='100%'
                    url ="https://player.vimeo.com/video/673151536"
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={mutedToggle}
                    volume={0.5}
                    config={{
                        vimeo: {
                            playerOptions: {
                                playsinline: true,
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default Player;
